import axios from "axios";
import { CLEAR_ERRORS, DELIVERED_ORDERS_FAIL, DELIVERED_ORDERS_REQUEST, DELIVERED_ORDERS_SUCCESS, MY_ORDERS_FAIL, MY_ORDERS_REQUEST, MY_ORDERS_SUCCESS, NEW_INTERNAL_ORDER_FAIL, NEW_INTERNAL_ORDER_REQUEST, NEW_INTERNAL_ORDER_SUCCESS, ORDERS_INVOICE_FAIL, ORDERS_INVOICE_REQUEST, ORDERS_INVOICE_SUCCESS, SEND_DRIVER_FAIL, SEND_DRIVER_REQUEST, SEND_DRIVER_SUCCESS } from "../../types/businessTypes/businessOrders";

// New Internal Order
export const newInternalOrderData = (order) => async (dispatch) => {
    try {
        dispatch({ type: NEW_INTERNAL_ORDER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/createinternalorder`,
            order,
            config
        );

        dispatch({
            type: NEW_INTERNAL_ORDER_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: NEW_INTERNAL_ORDER_FAIL,
            payload: error.response.data,
        });
    }
};

// Get Business User Internal Orders
export const myInternalOrders = (id) => async (dispatch) => {
    try {
        dispatch({ type: MY_ORDERS_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getorderdetails/${id}`,
            config
        );

        dispatch({
            type: MY_ORDERS_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MY_ORDERS_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Get Delivered Orders
export const getDeliveredOrders = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELIVERED_ORDERS_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getdeliveredordersbyid/${id}`,
            config
        );

        dispatch({
            type: DELIVERED_ORDERS_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DELIVERED_ORDERS_FAIL,
            payload: error.response.data,
        });
    }
};


// Get Order Invoice
export const gteOrderInvoice = (id, orderType) => async (dispatch) => {
    try {
        dispatch({ type: ORDERS_INVOICE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getinvoiceorderdetails/${id}/${orderType}`,
            config
        );

        dispatch({
            type: ORDERS_INVOICE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ORDERS_INVOICE_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Send Driver Orders
export const sendDriverOrders = (order,orderId) => async (dispatch) => {
    try {
        dispatch({ type: SEND_DRIVER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/senddriver/${orderId}`,
            order,
            config
        );

        dispatch({
            type: SEND_DRIVER_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SEND_DRIVER_FAIL,
            payload: error.response,
        });
    }
};

// Clear All Errors
export const clearOrderErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}