import { ADD_SALES_REPORT_FAIL, ADD_SALES_REPORT_REQUEST, ADD_SALES_REPORT_SUCCESS, CLEAR_ERRORS, GET_SALES_REPORT_FAIL, GET_SALES_REPORT_REQUEST, GET_SALES_REPORT_SUCCESS } from "../../types/salesTypes/salesReport";

// Add sales report reducer
export const addSalesReportReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case ADD_SALES_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_SALES_REPORT_SUCCESS:
            return {
                loading: false,
                success: payload.message,
                reportData: payload,
            };
        case ADD_SALES_REPORT_FAIL:
            return {
                loading: false,
                error: payload,
            };
        
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: null,
            };
        default:
            return state;
    }
};

// sales person delivery report
export const getSalesReportReducer = (state = { reports: {} }, { type, payload }) => {
    switch (type) {
        case GET_SALES_REPORT_REQUEST:
            return {
                loading: true,
            };
        case GET_SALES_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                reports: payload,
            };
        case GET_SALES_REPORT_FAIL:
            return {
                ...state,
                loading: false,
                reports: null,
                error: payload,
            };
        default:
            return state;
    }
}