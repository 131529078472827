import { useState } from "react";
import { Button, Form, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { addSalesReport, clearReportErrors, getSalesReport } from "../../../store/actions/salesActions/salesReport";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import moment from "moment";
import { useRef } from "react";

const AddSalesReport = () =>{

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const ref = useRef();

    const [salesReport, setSalesReport] = useState('');

    const { salesUserDetail } = useSelector((state) => state.salesUserDetails);
    const { success, error } = useSelector((state) => state.salesAddReport);
    const { reports } = useSelector((state) => state.salesReport);

    // Submit Report Form
    const handleReportSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set("salesReport", salesReport);
        formData.set("customer_id", salesUserDetail && salesUserDetail.user.customer_id);

        dispatch(addSalesReport(formData))
        ref.current.value = ""
    }

    // Change Report File
    const handleReportChange = (e) => {
        setSalesReport(e.target.files[0])
    }

    useEffect(() => {

        // Image Upload error
        if (error) {
            enqueueSnackbar("Upload another report.", { variant: "error" });
            dispatch(clearReportErrors());
        }

        // Image uppload success
        if (success) {
            enqueueSnackbar(success, { variant: "success" });
            dispatch(clearReportErrors());
            dispatch(getSalesReport(salesUserDetail && salesUserDetail.user.customer_id));
        }

        if(salesUserDetail && salesUserDetail.status === 1){
           
            dispatch(getSalesReport(salesUserDetail && salesUserDetail.user.customer_id));
            
        }

    }, [dispatch, salesUserDetail, success, error, enqueueSnackbar])

    return(
        <div className="px-15px py-6 w-full xl:w-3/4 mx-auto">
            <Form className="sales-add-report-form business_profile_form" method="post" onSubmit={handleReportSubmit} encType="multipart/form-data">
                <Form.Group className="mb-20px" controlId="salesperson_report">
                    <Form.Control ref={ref} type="file" name="salesperson_report" required onChange={handleReportChange} accept=".xlsx, .xls, .csv" className="report_filter_input" />
                </Form.Group>

                <Form.Group className="text-left">
                    <Button type="submit" className="btn-profile-submit">Add Report</Button>
                </Form.Group>

            </Form>

            <Table className="report_table" id="reports_excel_table">
                <thead>
                    <tr>
                        <th className="text-center" style={{width: '10%'}}>S.No.</th>
                        <th className="text-center" style={{width: '30%'}}>Date Added</th>
                        <th style={{width: '60%'}}>Report File</th>
                    </tr>
                </thead>
                <tbody>
                    {reports && reports.reports && reports.reports.length >= 1 ? 
                        reports.reports.map((item,i) => (
                            <tr key={i} className={ i%2 ===0 ? "grey": "white" }>
                                <td data-label="S.No." className="text-center">{i+1}</td>
                                <td data-label="Date Added" className="text-center">{moment(item.added_date).format("MM/DD/Y")}</td>
                                <td data-label="File"><a href={`${process.env.REACT_APP_API_URL}/images/sales_report/${item.sales_report}`} target="_blank" rel="noreferrer" className="no-underline">{item.sales_report}</a></td>
                            </tr>
                        ))
                    : 
                        <tr>
                            <td colSpan={3}>No Record Found</td>
                        </tr>
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default AddSalesReport