import { combineReducers } from "redux";
import { registerTypeReducer } from "./registerTypeReducer";
import { businessDetailsReducer, businessProfileReducer, businessUserReducer, setBusinessSession } from "./businessReducers/businessUsers";
import { deliveredOrdersReducer, myInternalOrdersReducer, newInternalOrderReducer, orderInvoiceReducer, sendDriverOrdersReducer } from "./businessReducers/businessOrders";
import { getScanDriversReducers } from "./businessReducers/scanDrivers";
import { getChatAdminReducers } from "./businessReducers/chatAdmins";
import { getAdsImagesReducer, getPhoneCountReducer, getSmsQueueReducer, uploadAdsImageReducer } from "./businessReducers/MarketingAds";
import { downloadPhoneTripsReducer, getTripsRequestedReducers } from "./businessReducers/tripsRequested";
import { salesCommissionReportReducer, salesDeliveryReportReducer, salesDetailsReducer, salesProfileReducer } from "./salesReducers/salesUsers";
import { addSalesReportReducer, getSalesReportReducer } from "./salesReducers/salesReport";

export default combineReducers({
    registerType : registerTypeReducer,
    scanDrivers: getScanDriversReducers,
    chatAdmin: getChatAdminReducers,
    businessUser: businessUserReducer,
    businessProfile: businessProfileReducer,
    businessUserDetails: businessDetailsReducer,
    userDetails: setBusinessSession,
    newInternalOrder: newInternalOrderReducer,
    myInternalOrders: myInternalOrdersReducer,
    ordersInvoice: orderInvoiceReducer,
    phoneNoCount: getPhoneCountReducer,
    adsData: uploadAdsImageReducer,
    adsImageLists: getAdsImagesReducer,
    smsQueueLists: getSmsQueueReducer,
    downloadPhones: downloadPhoneTripsReducer,
    deliveredOrders: deliveredOrdersReducer,
    tripOrders: getTripsRequestedReducers,
    sendDrivers: sendDriverOrdersReducer,

    // sales users 
    salesUserDetails: salesDetailsReducer,
    salesProfile: salesProfileReducer,
    salesDeliveryReport: salesDeliveryReportReducer,
    salesAddReport: addSalesReportReducer,
    salesReport: getSalesReportReducer,
    salesCommissionReport: salesCommissionReportReducer,
})