import { CLEAR_ERRORS, DELIVERED_ORDERS_FAIL, DELIVERED_ORDERS_REQUEST, DELIVERED_ORDERS_SUCCESS, MY_ORDERS_FAIL, MY_ORDERS_REQUEST, MY_ORDERS_SUCCESS, NEW_INTERNAL_ORDER_FAIL, NEW_INTERNAL_ORDER_REQUEST, NEW_INTERNAL_ORDER_SUCCESS, ORDERS_INVOICE_FAIL, ORDERS_INVOICE_REQUEST, ORDERS_INVOICE_SUCCESS, SEND_DRIVER_FAIL, SEND_DRIVER_REQUEST, SEND_DRIVER_SUCCESS } from "../../types/businessTypes/businessOrders";

// new order reducer
export const newInternalOrderReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case NEW_INTERNAL_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case NEW_INTERNAL_ORDER_SUCCESS:
            return {
                loading: false,
                success: payload.message,
                order: payload,
            };
        case NEW_INTERNAL_ORDER_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: null,
            };
        default:
            return state;
    }
};

// get internal order reducer
export const myInternalOrdersReducer = (state = { orders: [] }, { type, payload }) => {
    switch (type) {
        case MY_ORDERS_REQUEST:
            return {
                loading: true,
            };
        case MY_ORDERS_SUCCESS:
            return {
                loading: false,
                orders: payload,
            };
        case MY_ORDERS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

// get internal order invoice
export const orderInvoiceReducer = (state = { orderInvoice: [] }, { type, payload }) => {
    switch (type) {
        case ORDERS_INVOICE_REQUEST:
            return {
                loading: true,
            };
        case ORDERS_INVOICE_SUCCESS:
            return {
                loading: false,
                orderInvoice: payload,
            };
        case ORDERS_INVOICE_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

// get delivered order reducer
export const deliveredOrdersReducer = (state = { deliveredOrder: [] }, { type, payload }) => {
    switch (type) {
        case DELIVERED_ORDERS_REQUEST:
            return {
                loading: true,
            };
        case DELIVERED_ORDERS_SUCCESS:
            return {
                loading: false,
                deliveredOrder: payload,
            };
        case DELIVERED_ORDERS_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

// send driver orders reducer
export const sendDriverOrdersReducer = (state = { sendDriver: [] }, { type, payload }) => {
    switch (type) {
        case SEND_DRIVER_REQUEST:
            return {
                loading: true,
            };
        case SEND_DRIVER_SUCCESS:
            return {
                loading: false,
                sendDriver: payload,
            };
        case SEND_DRIVER_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};