// Upload Sales Report
export const ADD_SALES_REPORT_REQUEST = "ADD_SALES_REPORT_REQUEST";
export const ADD_SALES_REPORT_SUCCESS = "ADD_SALES_REPORT_SUCCESS";
export const ADD_SALES_REPORT_FAIL = "ADD_SALES_REPORT_FAIL";

// Get Sales User Report
export const GET_SALES_REPORT_REQUEST = "GET_SALES_REPORT_REQUEST";
export const GET_SALES_REPORT_SUCCESS = "GET_SALES_REPORT_SUCCESS";
export const GET_SALES_REPORT_FAIL = "GET_SALES_REPORT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";