import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDo7KPGW0GIP-xhZbWKm_fqRbWOFXW5qnM",
  authDomain: "staging-buysing.firebaseapp.com",
  databaseURL: "https://staging-buysing-default-rtdb.firebaseio.com",
  projectId: "staging-buysing",
  storageBucket: "staging-buysing.firebasestorage.app",
  messagingSenderId: "1084278382149",
  appId: "1:1084278382149:web:611190433832c9e3ee8d2c"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);